import React from 'react';
import { LocaleTypes } from '../data/locales';
import {
  IndustriasHero,
  IndustriasBenefits,
  IndustriasCTA,
} from '../components/industrias'
import {IndexCarousel} from '../components/Index';
import IndexLayout from '../layouts';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import '../styles/styles.scss'

interface IndustriasProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location
}

const industrias: React.FC<IndustriasProps> = ({ pathContext, location }) => {
  const content = (
    <>
      <SEO title="Industrias" description="Industrias" />

      <div className="ingenio-bg-hero-industry">
        <IndustriasHero/>
      </div>

      <IndustriasBenefits />
      <IndexCarousel/>
      <IndustriasCTA />

      <div className="ingenio-bg-footer">
        <Footer />
      </div>
    </>
  )

  return (
    <IndexLayout location={location} content={content}  />
  )
}

export default industrias;